import { ElementClassExtensions } from 'Utils/global'; // FIXME !!! refactoring required for 'removeClass'
import 'imgix.js'; // https://www.imgix.com/imgix-js
//import 'Vendors/imgix/imgix.min.js'
import 'Configs/lazysizesconfig';
import 'lazysizes/plugins/respimg/ls.respimg';
//import 'lazysizes/plugins/print/ls.print';                // uncomment when need this
//import 'lazysizes/plugins/parent-fit/ls.parent-fit';      // uncomment when need this
//import 'lazysizes/plugins/optimumx/ls.optimumx';          // uncomment when need this
//import 'lazysizes/plugins/aspectratio/ls.aspectratio';    // uncomment when need this
import lazySizes from 'lazysizes';


ElementClassExtensions();

function initPictures() {

    /* ixlib param
    For security and diagnostic purposes, we default to signing all requests with the language and version of library used to generate the URL.
    This can be disabled by setting the includeLibraryParam configuration option to false.
    */
    window.imgix.config.includeLibraryParam = false;

    // Lazy Loading With lazysizes
    window.imgix.config.srcAttribute = 'data-src';
    window.imgix.config.srcsetAttribute = 'data-srcset';
    window.imgix.config.sizesAttribute = 'data-sizes';

    var update_lazySizes = function () {
        window.lazySizesConfig.expand = 370;
        window.lazySizesConfig.loadMode = 2;
        //window.lazySizesConfig.loadHidden = true;
        //lazySizes.loader.checkElems();


        let elements = document.querySelectorAll('.lazydefer');
        elements.forEach(function (element, i) {
            element.style.removeProperty('visibility');
            //element.style.removeProperty('display');
            element.removeClass('lazydefer');
        });
    };
    var cbInitPictures = function () {
        imgix.init({
            force: true
        });
        lazySizes.init();
    };

    cbInitPictures();
    //AppLoader.ready(function () {
    //$(document).ready(function () {
    window.setTimeout(update_lazySizes, 1000);
    //});
}

// FIXME !!! WebPerformance :: initPictures :: Check what is better
// version 1
//$(document).ready(function () {
//    window.setTimeout(initPictures, 500);
//});
//window.addEventListener('load', initPictures);

// version 2
//AppLoader.ready(function () {
//    initPictures();
//});

// version 3
initPictures();
